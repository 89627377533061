<template>
  <div class="rounding-surveillance">
    <LoadingSpinner :isLoading="isLoading" />
    <div v-bind:class="mobileMenuActive ? 'block' : 'hidden'" @click="closeMenu()" class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"></div>
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-left flex flex-wrap">
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="!toggle_map ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeToggleMap(false)">{{ $t("secure_tool.log") }}</div>
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="toggle_map ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeToggleMap(true)">{{ $t("secure_tool.map") }}</div>
        </div>
        <div class="float-right flex flex-wrap">
          <div class="datepicker-wrapper mx-2 mt-2 lg:mt-0">
            <date-picker :lang="date_picker_lang" :key="date_picker_langKey" v-model="date_range" type="date" range :editable="false" :clearable="false" @change="getGPSLogs()" :disabled-date="disableDate">
              <template v-slot:footer="{ emit }">
                <div class="text-left hidden lg:block">
                  <button class="datepicker-action-btn mr-1" @click="selectToday(emit)">{{ $t("secure_tool.today") }}</button>
                  <button class="datepicker-action-btn mr-1" @click="selectCurrentWeek(emit)">{{ $t("secure_tool.this_week") }}</button>
                  <button class="datepicker-action-btn mr-1" @click="selectLastWeek(emit)">{{ $t("secure_tool.last_week") }}</button>
                  <button class="datepicker-action-btn mr-1" @click="selectCurrentMonth(emit)">{{ $t("secure_tool.this_month") }}</button>
                  <button class="datepicker-action-btn mr-1" @click="selectLastMonth(emit)">{{ $t("secure_tool.last_month") }}</button>
                </div>
              </template>
            </date-picker>
          </div>
          <div class="select-wrapper mx-2 mt-2 lg:mt-0" v-if="customers">
            <select class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-1 py-1" v-model="selected_customer" @change="selectChangeCustomer()">
              <option value="">{{ $t("secure_track.all_customers") }}</option>
              <option v-for="c in customers" :key="c._id" :value="c._id">{{ c.name }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="w-full bg-gray-50 px-2 py-2 clearfix mt-2">
        <div v-if="!toggle_map" class="float-left flex flex-wrap">
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 1 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(1)">{{ $t("secure_tool.all") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 2 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(2)">{{ $t("secure_tool.live_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 3 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(3)">{{ $t("secure_tool.internal_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 4 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(4)">{{ $t("secure_tool.man_down") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 5 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(5)">{{ $t("secure_tool.low_battery") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 6 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(6)">{{ $t("secure_tool.radio_beacon") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 7 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(7)">{{ $t("secure_tool.test_alarm_performed") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 8 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(8)">{{ $t("secure_tool.timer_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 9 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(9)">{{ $t("secure_tool.on_off") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 10 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(10)">{{ $t("secure_tool.loading") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 11 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(11)">{{ $t("secure_tool.gsm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 12 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(12)">{{ $t("secure_tool.geofence") }}</button>
        </div>
        <div v-if="toggle_map" class="float-left flex flex-wrap">
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 1 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(1)">{{ $t("secure_tool.all") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 2 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(2)">{{ $t("secure_tool.live_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 6 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(6)">{{ $t("secure_tool.internal_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 7 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(7)">{{ $t("secure_tool.man_down") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 3 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(3)">{{ $t("secure_tool.live_alarm_tracking") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 4 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(4)">{{ $t("secure_tool.follow_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 5 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(5)">{{ $t("secure_tool.current_location") }}</button>
        </div>
      </div>

      <div class="w-full mt-6">
        <div v-show="!toggle_map">
          <vue-good-table
            styleClass="vgt-table"
            row-style-class="text-sm"
            :columns="columns"
            :rows="rows_filtered"
            :search-options="{
              enabled: true,
              placeholder: 'Sök',
            }"
            :pagination-options="{
              enabled: true,
              perPage: 50,
              perPageDropdown: [50, 100, 200],
              dropdownAllowAll: false,
            }"
          >
            <div slot="table-actions">
              <div class="flex flex-wrap">
                <button class="btn-blue mx-1 mt-2 lg:mt-0" @click="exportData()">
                  {{ $t("export") }}
                  <BaseIcon icon="file-excel" class="ml-1" />
                </button>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'view' && (props.row.log_type == 'push_button' || props.row.log_type == 'call_b' || props.row.log_type == 'man_down')">
                <button class="btn-blue-outline" @click.prevent="focusPushButton(props.row)">{{ $t("view") }}</button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
        <div v-show="toggle_map" class="unit-gps-wrapper">
          <div class="w-full lg:w-5/6 map-container">
            <GmapMap :center="center" :zoom="zoom" map-type-id="roadmap" class="google_map_wrapper" ref="map">
              <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false"> </GmapInfoWindow>

              <GmapCluster :zoom-on-click="true">
                <GmapMarker v-for="(m, index) in markers_filtered" :position="m.position" :clickable="true" :draggable="false" @mouseover="showInfoWindow($event, m.infoText, true)" @mouseout="showInfoWindow($event, m.infoText, false)" :key="index" :icon="{ url: m.icon }"></GmapMarker>
              </GmapCluster>

              <GmapCluster :zoom-on-click="true" :styles="[{ url: require('@/assets/icon/warning.png'), width: 40, height: 34, textSize: 18, anchorText: [4, 0], textColor: '#fff' }]">
                <GmapMarker v-for="(m, i) in push_button_markers_filtered" :key="i" :position="m.position" :clickable="true" :draggable="false" :icon="{ url: require('@/assets/icon/map-warning.svg') }" @mouseover="showInfoWindow($event, m.infoText, true)" @mouseout="showInfoWindow($event, m.infoText, false)"></GmapMarker>
              </GmapCluster>

              <GmapPolyline v-for="(p, index) in track_alarm_paths_filtered" :key="index" :path="p.path" :options="p.options" @mouseover="showInfoWindow($event, p.infoText, true)" @mouseout="showInfoWindow($event, p.infoText, false)"></GmapPolyline>
            </GmapMap>
          </div>
          <transition enter-active-class="transition ease-out duration-100" enter-class="transform translate-x-2 opacity-0" enter-to-class="transform translate-x-0 opacity-100" leave-active-class="transition ease-in duration-75" leave-class="transform translate-x-0 opacity-100" leave-to-class="transform translate-x-2 opacity-0">
            <div class="absolute top-0 right-0 min-h-screen w-4/6 md:w-2/6 lg:min-h-full lg:w-1/6 bg-white position-inherit z-30" v-if="mobileMenuActive">
              <div class="scroll-container-alarm border border-secondary-300">
                <div class="alarm-item" v-if="alarms_filtered && alarms_filtered.length == 0">
                  <div class="alarm-name">Inga larm hittade</div>
                </div>
                <div v-for="a in alarms_filtered" :key="a._id" class="alarm-item bg-white border-b border-secondary-300">
                  <div @click="focusAlarm(a.imei_number)" class="hover:bg-primary-50 cursor-pointer py-2 px-3">
                    <div class="text-sm font-bold text-primary-500">{{ a.customer_name }}</div>
                    <div class="text-sm font-bold">{{ a.alarm_unit_name }}</div>
                    <div class="text-sm mt-2">{{ a.unit_name }}</div>
                    <div class="text-xs">{{ a.phone }}</div>
                    <div class="text-xs">{{ a.imei_number }}</div>
                    <div class="text-xxs mt-2 text-secondary-600 pt-1">
                      <span v-if="new RegExp('^SRT').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                      <span v-if="new RegExp('^TWIG').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 * 2.5 }">⬤</span>
                      <span v-if="new RegExp('^EMERIT').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                      <span v-if="new RegExp('^TELTONIKA').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                      {{ a.lastSeen }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";

export default {
  name: "AlarmCenterSecurtool",
  title() {
    return `SecurTool | SecurCloud`;
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      mobileMenuActive: false,
      mapFullScreenControl: true,
      pathFilter: true,
      isLoading: false,
      id: this.$route.params.id,
      toggle_map: false,
      zoom: this.$store.state.mapZoom,
      center: this.$store.state.mapCenter,
      table_tab: 1,
      map_filter: 1,
      alarm_center: null,
      customers: null,
      units: null,
      alarms: [],
      alarms_filtered: null,
      selected_customer: "",
      date_range: [],
      gps: [],
      gps_log: [],
      track_alarm: [],
      rows: [],
      rows_customers: [],
      rows_filtered: [],
      markers: [],
      markers_filtered: [],
      push_button_markers: [],
      push_button_markers_filtered: [],
      track_alarm_paths: [],
      track_alarm_paths_customer: [],
      track_alarm_paths_filtered: [],
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: { content: "", pixelOffset: { width: 0, height: -35 } },
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
    };
  },

  methods: {
    async getGPSLogs() {
      try {
        this.isLoading = true;

        let start_date = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
        let end_date = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/gps-log/alarm-center/${this.id}/${start_date}/${end_date}`);
        this.gps_log = response.data.logs;
        this.gps = response.data.gps;
        this.track_alarm = response.data.track_alarm;
        this.alarm_center = response.data.alarm_center;
        this.customers = response.data.customers;
        this.units = response.data.units;

        this.rows = [];
        this.rows_customers = [];
        this.rows_filtered = [];
        this.markers = [];
        this.markers_filtered = [];
        this.alarms = [];
        this.alarms_filtered = [];
        this.push_button_markers = [];
        this.push_button_markers_filtered = [];
        this.track_alarm_paths = [];
        this.track_alarm_paths_customer = [];
        this.track_alarm_paths_filtered = [];

        this.setPageTitle(`${this.alarm_center.name} - SecurTool`, "alarm_center_securtool");

        // alarms array
        let larms = [];
        response.data.alarms.forEach((element) => {
          let i = _.find(this.gps, { imei_number: element.imei_number });
          if (i) {
            let lastSeenObj = this.getAlarmLastSeenTime(i.updatedAt);
            element.lastSeen = lastSeenObj.lastSeen;
            element.lastSeenTime = lastSeenObj.lastSeenTime;

            let unit = this.getUnit(element.parent_id);
            element.alarm_unit_name = unit.name;
            element.customer_id = unit.parent_id;
            element.customer_name = this.getCustomerName(unit.parent_id);

            larms.push(element);

            if (i.gps_pos.lat != null) {
              // Icon depending on device type
              let icon = require("@/assets/icon/new_small_map_marker.png");

              if (new RegExp(/^TELTONIKA/g).test(element.type)) icon = require("@/assets/icon/car.png");
              else if (element.type == "SRT430") icon = require("@/assets/icon/small_SRT430.png");
              else if (element.type == "SRT341") icon = require("@/assets/icon/srt341.png");
              else if (element.type == "SRT406") icon = require("@/assets/icon/small_SRT406.png");
              else if (element.type == "SRT406i") icon = require("@/assets/icon/small_SRT406.png");
              else if (/^TWIG/.test(element.type)) {
                icon = require("@/assets/icon/twig_map_marker.png");

                if (element.type == "TWIG One EX") icon = require("@/assets/icon/twig_one_ex.png");
                else if (/^TWIG One/.test(element.type)) icon = require("@/assets/icon/twig_one.png");
              }
              else if (/^EMERIT/.test(element.type)) {
                icon = require("@/assets/icon/emerit_watch.png");

                if (element.type == "EMERIT e-B10") icon = require("@/assets/icon/smartphone.png");
              }

              this.markers.push({
                icon: icon,
                position: { lat: i.gps_pos.lat, lng: i.gps_pos.lng },
                infoText: `Larmets namn: <b>${element.unit_name}</b><br>
                          Kundnamn: <b>${element.customer_name}</b><br>
                          Verksamhetnamn: <b>${element.alarm_unit_name}</b><br>
                          `,
                unit_number: element.parent_id,
                customer_id: unit.parent_id,
                imei_number: element.imei_number,
              });
            }
          }
        });
        this.alarms = larms;
        this.markers_filtered = this.markers;

        this.gps_log.forEach((el) => {
          el.customer_id = "";
          el.customer_name = "";

          let unit = this.getUnit(el.unit_number);
          if (unit) {
            el.customer_id = unit.parent_id;
            el.customer_name = this.getCustomerName(unit.parent_id);
          }

          // table
          if (el.log_type != "location_update") this.rows.push(el);

          if (el.log_type == "push_button" && el.gps_pos.lat != null) {
            this.push_button_markers.push({
              type: "push_button",
              position: el.gps_pos,
              unit_number: el.unit_number,
              customer_id: el.customer_id,
              imei_number: el.imei_number,
              timestamp: el.createdAt,
              infoText: `<b>Push Button</b><br>
                        Larmets namn: <b>${el.alarm_name}</b><br>
                        Kundnamn: <b>${el.customer_name}</b><br>
                        Verksamhetnamn: <b>${el.unit_name}</b><br>
                        Timestamp: <b>${this.formatDate(el.createdAt)}</b><br>
                        Lat: <b>${el.gps_pos.lat.toFixed(3)}</b>, Lng: <b>${el.gps_pos.lng.toFixed(3)}</b>
                        `,
            });
          }
          if (el.log_type == "call_b" && el.gps_pos.lat != null) {
            this.push_button_markers.push({
              type: "call_b",
              position: el.gps_pos,
              unit_number: el.unit_number,
              customer_id: el.customer_id,
              imei_number: el.imei_number,
              timestamp: el.createdAt,
              infoText: `<b>Internlarm</b><br>
                        Larmets namn: <b>${el.alarm_name}</b><br>
                        Kundnamn: <b>${el.customer_name}</b><br>
                        Verksamhetnamn: <b>${el.unit_name}</b><br>
                        Timestamp: <b>${this.formatDate(el.createdAt)}</b><br>
                        Lat: <b>${el.gps_pos.lat.toFixed(3)}</b>, Lng: <b>${el.gps_pos.lng.toFixed(3)}</b>
                        `,
            });
          }
          if (el.log_type == "man_down" && el.gps_pos.lat != null) {
            this.push_button_markers.push({
              type: "man_down",
              position: el.gps_pos,
              unit_number: el.unit_number,
              customer_id: el.customer_id,
              imei_number: el.imei_number,
              timestamp: el.createdAt,
              infoText: `<b>Man down</b><br>
                        Larmets namn: <b>${el.alarm_name}</b><br>
                        Kundnamn: <b>${el.customer_name}</b><br>
                        Verksamhetnamn: <b>${el.unit_name}</b><br>
                        Timestamp: <b>${this.formatDate(el.createdAt)}</b><br>
                        Lat: <b>${el.gps_pos.lat.toFixed(3)}</b>, Lng: <b>${el.gps_pos.lng.toFixed(3)}</b>
                        `,
            });
          }
        });

        this.rows = this.rows.sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        this.rows_filtered = this.rows;
        this.rows_customers = this.rows;
        this.alarms_filtered = this.alarms;
        this.push_button_markers_filtered = this.push_button_markers;

        this.track_alarm.forEach((track_path) => {
          let path = _.map(track_path.gps_path, "position");
          let alarm = _.find(this.alarms, { imei_number: track_path.imei_number });

          let unit_name = "";
          let customer_name = "";
          let unit = this.getUnit(alarm.parent_id);
          if (unit) {
            unit_name = unit.name;
            customer_name = this.getCustomerName(unit.parent_id);
          }

          let tracking_type = track_path.tracking_type == "push_button" ? "Push-Button Spårning" : `Följ larm - ${track_path.user_name}`;
          let path_color = track_path.tracking_type == "push_button" ? "red" : this.getRandomPathColor();

          this.track_alarm_paths.push({
            path: path,
            imei_number: track_path.imei_number,
            customer_id: unit.parent_id,
            type: track_path.tracking_type == "push_button" ? "pushb_tracking" : "user_tracking",
            options: {
              strokeColor: path_color,
              strokeOpacity: track_path.tracking_type == "push_button" ? 0.7 : 0.3,
              strokeWeight: 3,
            },
            infoText: `<b>${tracking_type}</b><br>
                      Larmets namn: <b>${alarm.unit_name}</b><br>
                      Kundnamn: <b>${customer_name}</b><br>
                      Verksamhetnamn: <b>${unit_name}</b><br>
                      Timestamp: <b>${this.formatDate(track_path.createdAt)}</b>
                      `,
          });
        });

        this.track_alarm_paths_customer = this.track_alarm_paths;
        this.track_alarm_paths_filtered = this.track_alarm_paths;

        this.isLoading = false;

        // apply existing filter
        this.selectChangeCustomer();
      } catch (error) {
        this.isLoading = false;
        this.handleError(error);
      }
    },

    getAlarmLastSeenTime(updatedAt) {
      return {
        lastSeen: this.moment(updatedAt).fromNow(),
        lastSeenTime: this.moment().diff(this.moment(updatedAt)),
      };
    },

    focusAlarm(imei) {
      var vm = this;

      let current_pos = _.find(this.gps, { imei_number: imei });

      if (current_pos && current_pos.gps_pos.lat != null) {
        vm.$refs.map.$mapObject.panTo({ lat: current_pos.gps_pos.lat, lng: current_pos.gps_pos.lng });
        vm.$refs.map.$mapObject.setZoom(16);
      }

      if (this.isMobile) this.mobileMenuActive = false;
    },

    showInfoWindow(event, infoText, show) {
      if (show) {
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;
      } else this.infoWinOpen = show;
    },

    changeToggleMap(value) {
      this.toggle_map = value;
      if (this.toggle_map) this.generateBounds();
    },

    changeTableFilter(value) {
      this.table_tab = value;

      if (this.table_tab == 1) this.rows_filtered = this.rows_customers;
      else if (this.table_tab == 2) this.rows_filtered = this.getFilterData("push_button");
      else if (this.table_tab == 3) this.rows_filtered = this.getFilterData("call_b");
      else if (this.table_tab == 4) this.rows_filtered = this.getFilterData("man_down");
      else if (this.table_tab == 5) this.rows_filtered = this.getFilterData("low_battery");
      else if (this.table_tab == 6) this.rows_filtered = this.getFilterData("rft_");
      else if (this.table_tab == 7) this.rows_filtered = this.getFilterData("test_alarm_confirm");
      else if (this.table_tab == 8) this.rows_filtered = this.getFilterData("timer_alarm_stop");
      else if (this.table_tab == 9) this.rows_filtered = this.getFilterData("device_");
      else if (this.table_tab == 10) this.rows_filtered = this.getFilterData("charge_");
      else if (this.table_tab == 11) this.rows_filtered = this.getFilterData("alarm_");
      else if (this.table_tab == 12) this.rows_filtered = this.getFilterData("geofence");
    },

    getFilterData(type) {
      let log_type = type;

      if (log_type == "rft_") {
        let r = _.filter(this.rows_customers, function(o) {
          return new RegExp(/^rft_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "device_") {
        let r = _.filter(this.rows_customers, function(o) {
          return new RegExp(/^device_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "charge_") {
        let r = _.filter(this.rows_customers, function(o) {
          return new RegExp(/^charge_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "alarm_") {
        let r = _.filter(this.rows_customers, function(o) {
          return new RegExp(/^alarm_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "geofence") {
        let r = _.filter(this.rows_customers, function(o) {
          return new RegExp(/^geofence/).test(o.log_type);
        });
        return r;
      } else {
        let r = _.filter(this.rows_customers, { log_type: log_type });
        return r;
      }
    },

    filterTableByCustomer() {
      if (this.selected_customer != "") {
        this.rows_customers = _.filter(this.rows, { customer_id: this.selected_customer });
      } else {
        this.rows_customers = this.rows;
      }
    },

    filterMapByCustomer() {
      if (this.selected_customer != "") {
        this.markers_filtered = _.filter(this.markers, { customer_id: this.selected_customer });
        this.push_button_markers_filtered = _.filter(this.push_button_markers, { customer_id: this.selected_customer });

        this.track_alarm_paths_customer = _.filter(this.track_alarm_paths, { customer_id: this.selected_customer });
        this.track_alarm_paths_filtered = this.track_alarm_paths_customer;
      } else {
        this.markers_filtered = this.markers;
        this.push_button_markers_filtered = this.push_button_markers;

        this.track_alarm_paths_customer = this.track_alarm_paths;
        this.track_alarm_paths_filtered = this.track_alarm_paths;
      }
    },

    filterAlarmsByCustomer() {
      if (this.selected_customer != "") {
        this.alarms_filtered = _.filter(this.alarms, { customer_id: this.selected_customer });
      } else {
        this.alarms_filtered = this.alarms;
      }
    },

    changeMapFilter(value) {
      this.map_filter = value;

      this.filterMapByCustomer();

      if (this.map_filter != 1 && this.map_filter != 2 && this.map_filter != 6 && this.map_filter != 7) this.push_button_markers_filtered = [];
      if (this.map_filter != 1 && this.map_filter != 5) this.markers_filtered = [];

      if (this.map_filter != 1) this.track_alarm_paths_filtered = [];

      if (this.map_filter == 2) this.push_button_markers_filtered = _.filter(this.push_button_markers_filtered, { type: "push_button" });
      if (this.map_filter == 6) this.push_button_markers_filtered = _.filter(this.push_button_markers_filtered, { type: "call_b" });
      if (this.map_filter == 7) this.push_button_markers_filtered = _.filter(this.push_button_markers_filtered, { type: "man_down" });

      if (this.map_filter == 3) this.track_alarm_paths_filtered = _.filter(this.track_alarm_paths_customer, { type: "pushb_tracking" });
      else if (this.map_filter == 4) this.track_alarm_paths_filtered = _.filter(this.track_alarm_paths_customer, { type: "user_tracking" });

      this.infoWinOpen = false;
    },

    selectChangeCustomer() {
      this.filterTableByCustomer();
      this.changeTableFilter(this.table_tab);
      this.filterAlarmsByCustomer();
      this.changeMapFilter(this.map_filter);
      this.generateBounds();
      this.infoWinOpen = false;
    },

    focusPushButton(report) {
      this.toggle_map = true;

      if (report.log_type == "push_button") {
        this.map_filter = 2;
        this.changeMapFilter(this.map_filter);

        let pushb_marker = _.find(this.push_button_markers_filtered, { timestamp: report.createdAt, type: "push_button" });
        if (pushb_marker) this.showInfoWindow({ latLng: pushb_marker.position }, pushb_marker.infoText, true);
      }

      if (report.log_type == "call_b") {
        this.map_filter = 6;
        this.changeMapFilter(this.map_filter);

        let callb_marker = _.find(this.push_button_markers_filtered, { timestamp: report.createdAt, type: "call_b" });
        if (callb_marker) this.showInfoWindow({ latLng: callb_marker.position }, callb_marker.infoText, true);
      }

      if (report.log_type == "man_down") {
        this.map_filter = 7;
        this.changeMapFilter(this.map_filter);

        let mandown_marker = _.find(this.push_button_markers_filtered, { timestamp: report.createdAt, type: "man_down" });
        if (mandown_marker) this.showInfoWindow({ latLng: mandown_marker.position }, mandown_marker.infoText, true);
      }

      this.$refs.map.$mapObject.panTo({ lat: report.gps_pos.lat, lng: report.gps_pos.lng });
      this.$refs.map.$mapObject.setZoom(16);
    },

    exportData() {
      const newColumns = this.rows_filtered.map((item) => ({
        Kund: item.customer_name,
        Verksamhet: item.unit_name,
        Larmnamn: item.alarm_name,
        Timestamp: item.createdAt,
        Report: item.log_report,
        Latitude: item.gps_pos.lat,
        Longitude: item.gps_pos.lng,
      }));

      let filtered = newColumns.filter((el) => Object.keys(el).length);

      this.excelExport(filtered, "log", "larm_logs");
    },

    getUnit(id) {
      let u = _.find(this.units, { unit_id: id });
      if (u) return u;
      else return null;
    },

    getCustomerName(id) {
      let c = _.find(this.customers, { _id: id });
      if (c) return c.name;
      else return "";
    },

    formatDate(date) {
      return this.moment(date)
        .tz("Europe/Stockholm")
        .format("ddd, YYYY-MM-DD, HH:mm");
    },

    initDateRange() {
      const start = new Date(this.moment().startOf("day"));
      const end = new Date(this.moment().endOf("day"));
      this.date_range = [start, end];
    },

    disableDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    selectToday(emit) {
      const start = new Date(this.moment().startOf("day"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectCurrentWeek(emit) {
      const start = new Date(this.moment().startOf("isoWeek"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectLastWeek(emit) {
      const start = new Date(
        this.moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
      );
      const end = new Date(
        this.moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
      );
      const date = [start, end];
      emit(date);
    },

    selectCurrentMonth(emit) {
      const start = new Date(this.moment().startOf("month"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectLastMonth(emit) {
      const start = new Date(
        this.moment()
          .subtract(1, "month")
          .startOf("month")
      );
      const end = new Date(this.moment(start).endOf("month"));
      const date = [start, end];
      emit(date);
    },

    getRandomPathColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    checkMobile() {
      var vm = this;

      this.isMobile = window.innerWidth < 1024;

      if (this.isMobile) {
        vm.mobileMenuActive = false;
        vm.mapFullScreenControl = false;
      } else {
        this.mobileMenuActive = true;
        this.mapFullScreenControl = true;
      }
    },

    closeMenu() {
      this.mobileMenuActive = false;
    },

    generateMobileMenu() {
      var vm = this;

      let controlDiv = this.generateGoogleMapActionDiv();

      controlDiv.addEventListener("click", () => {
        vm.mobileMenuActive = true;
      });

      vm.$refs.map.$mapPromise.then((map) => {
        map.controls[vm.google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
      });
    },

    generateBounds() {
      var vm = this;
      if (this.markers_filtered.length > 0) {
        setTimeout(() => {
          vm.$refs.map.$mapPromise.then((map) => {
            const bounds = new vm.google.maps.LatLngBounds();
            for (let m of this.markers_filtered) {
              bounds.extend(m.position);
            }

            var offset = 0.002;
            var center = bounds.getCenter();
            bounds.extend(new vm.google.maps.LatLng(center.lat() + offset, center.lng() + offset));
            bounds.extend(new vm.google.maps.LatLng(center.lat() - offset, center.lng() - offset));

            map.fitBounds(bounds);
          });
        }, 500);
      }
    },
  },

  watch: {
    "$i18n.locale"(newLocale) {
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },

  computed: {
    google: gmapApi,
    columns() {
      return [
        { label: this.$t("secur_tool_table.customer_name"), field: "customer_name" },
        { label: this.$t("secur_tool_table.unit_name"), field: "unit_name" },
        { label: this.$t("secur_tool_table.alarm_name"), field: "alarm_name" },
        { label: this.$t("secur_tool_table.timestamp"), field: "createdAt", type: "date", formatFn: this.formatDate },
        { label: this.$t("secur_tool_table.log_report"), field: "log_report" },
        { label: "", field: "view", sortable: false },
      ];
    },
  },

  created() {
    this.initDateRange();
    this.getGPSLogs();

    window.addEventListener("resize", this.checkMobile);
  },

  mounted() {
    this.generateMobileMenu();
    this.checkMobile();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
  },
};
</script>
